.t-productVariantSelectionMobile {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    z-index: 1000;
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out;
    opacity: 0;
    height: 100%;
    display: flex;
    align-items: flex-end;
}
  
.t-productVariantSelectionMobile.is-open {
    transform: translateY(0);
    opacity: 1;
    pointer-events: auto;
}
  
.layer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;
}
  
.t-productVariantSelectionMobile.is-open .layer {
    opacity: 1;
    pointer-events: auto;
}
