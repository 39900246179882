/* stylelint-disable scss/dollar-variable-pattern */
$sizeIcon: (
    '16x16': (w: 16, h: 16),
    '18x18': (w: 18, h: 18),
    '20x20': (w: 20, h: 20),
    '24x24': (w: 24, h: 24),
    '26x26': (w: 26, h: 26),
    '32x32': (w: 32, h: 32),
    '36x36': (w: 36, h: 36),
    '40x40': (w: 40, h: 40),
    '48x48': (w: 48, h: 48),
    '60x60': (w: 60, h: 60),
);

$iconSrc: (
    'verify': '#{$icon-path}ic_verify.svg',
    'whiteSearch': '#{$icon-path}ic_search_white.svg',
    'cart': '#{$icon-path}ic_cart.svg',
    'profileCircle': '#{$icon-path}ic_profile_circle.svg',
    'call': '#{$icon-path}ic_call.svg',
    'star': '#{$icon-path}ic_star.svg',
    'filledStar': '#{$icon-path}ic_star_filled.svg',
    'filledHeart': '#{$icon-path}ic_heart_filled.svg',
    'outlinedHeart': '#{$icon-path}ic_heart_outlined.svg',
    'outlinedHeart2': '#{$icon-path}ic_heart_outlined2.svg',
    'tick': '#{$icon-path}ic_tick.svg',
    'trashOutlined': '#{$icon-path}ic_trash_outlined.svg',
    'circleTick': '#{$icon-path}ic_tick_circle.svg',
    'chevronRight': '#{$icon-path}ic_chevron_right.svg',
    'chevronRightWhite': '#{$icon-path}ic_chevron_right_white.svg',
    'arrowLeft': '#{$icon-path}ic_arrow_left.svg',
    'arrowRightWhite': '#{$icon-path}ic_arrow_right_white.svg',
    'ticketDiscount': '#{$icon-path}ic_ticket_discount.svg',
    'verifyTurquoise': '#{$icon-path}ic_verify_turquoise.svg',
    'menu': '#{$icon-path}menu.svg',
    'bank': '#{$icon-path}ic_bank.svg',
);

.a-icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;

    @each $size,
        $val in $sizeIcon {
        &-#{$size} {
            height: map-get($val, 'h')+ px;
            width: map-get($val, 'w') + px;
        }
    }

    @each $icon,
        $val in $iconSrc {
        &-#{$icon} {
            background-image: url($val);
        }
    }
}
