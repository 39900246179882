.slick-dots {
    position: absolute;
    bottom: rem(-32);
    display: flex !important;
    justify-content: center;
    transition: 300ms ease-in-out;
    left: 50%;
    transform: translateX(-50%);

    &::-webkit-scrollbar {
        display: none;
    }

    li {
        flex-shrink: 0;
        display: flex;
        margin: 0;


        &:not(:first-child) {
            margin-left: rem(8);
            @include tablet-down {
                margin-left: rem(4);
            }
        }
    }

    .slick-active {
        span {
            background: $davys-grey;
        }
    }

    @include break-max(1025px) {
        position: relative;
        bottom: 0;
    }
}
