.slider-container {
    width: 100%;
    padding: 16px 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
  
.slider-track {
    position: relative;
    width: 100%;
    height: 8px;
    background-color: #ddd;
    border-radius: 5px;
    cursor: pointer;
}
  
.slider-fill {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #007bff;
    border-radius: 5px 0 0 5px;
}
  
.slider-thumb {
    position: absolute;
    top: -4px;
    width: 17px;
    height: 17px;
    background-color: #007bff;
    border-radius: 50%;
    cursor: pointer;
    transform: translateX(-50%);
    border: 3.5px solid #35cac1;
    z-index: 2;
}

@media only screen and (max-width: 992px) {
    .slider-thumb {
        top: -3.5px;
    } 
}
  
.slider-marks {
    position: absolute;
    top: -2px;
    width: 100%;
    display: flex;
    z-index: 1;
    justify-content: space-between;
}
  
.slider-mark {
    position: absolute;
    top: 0;
    width: 14px;
    height: 14px;
    background-color: #fff;
    border-radius: 50%;
    transform: translateX(-50%);
    border: 2px solid #52d2ca;
}
  
.slider-mark.active {
    background-color: #007bff;
}
  
.slider-mark.hide {
    display: none;
}
  
.slider-value {
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
}
