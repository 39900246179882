@font-face {
    font-family: 'SVN-Poppins';
    src: url('../assets/fonts/SVN-Poppins-BlackItalic.woff2') format('woff2'),
        url('../assets/fonts/SVN-Poppins-BlackItalic.woff') format('woff'),
        url('../assets/fonts/SVN-Poppins-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SVN-Poppins';
    src: url('../assets/fonts/SVN-Poppins-ExtraBoldItalic.woff2') format('woff2'),
        url('../assets/fonts/SVN-Poppins-ExtraBoldItalic.woff') format('woff'),
        url('../assets/fonts/SVN-Poppins-ExtraBoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SVN-Poppins';
    src: url('../assets/fonts/SVN-Poppins-Bold.woff2') format('woff2'),
        url('../assets/fonts/SVN-Poppins-Bold.woff') format('woff'),
        url('../assets/fonts/SVN-Poppins-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SVN-Poppins';
    src: url('../assets/fonts/SVN-Poppins-BoldItalic.woff2') format('woff2'),
        url('../assets/fonts/SVN-Poppins-BoldItalic.woff') format('woff'),
        url('../assets/fonts/SVN-Poppins-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SVN-Poppins';
    src: url('../assets/fonts/SVN-Poppins-Black.woff2') format('woff2'),
        url('../assets/fonts/SVN-Poppins-Black.woff') format('woff'),
        url('../assets/fonts/SVN-Poppins-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SVN-Poppins';
    src: url('../assets/fonts/SVN-Poppins-ExtraBold.woff2') format('woff2'),
        url('../assets/fonts/SVN-Poppins-ExtraBold.woff') format('woff'),
        url('../assets/fonts/SVN-Poppins-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SVN-Poppins';
    src: url('../assets/fonts/SVN-Poppins-ExtraLight.woff2') format('woff2'),
        url('../assets/fonts/SVN-Poppins-ExtraLight.woff') format('woff'),
        url('../assets/fonts/SVN-Poppins-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SVN-Poppins';
    src: url('../assets/fonts/SVN-Poppins-Light.woff2') format('woff2'),
        url('../assets/fonts/SVN-Poppins-Light.woff') format('woff'),
        url('../assets/fonts/SVN-Poppins-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SVN-Poppins';
    src: url('../assets/fonts/SVN-Poppins-Italic.woff2') format('woff2'),
        url('../assets/fonts/SVN-Poppins-Italic.woff') format('woff'),
        url('../assets/fonts/SVN-Poppins-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SVN-Poppins';
    src: url('../assets/fonts/SVN-Poppins-ExtraLightItalic.woff2') format('woff2'),
        url('../assets/fonts/SVN-Poppins-ExtraLightItalic.woff') format('woff'),
        url('../assets/fonts/SVN-Poppins-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SVN-Poppins';
    src: url('../assets/fonts/SVN-Poppins-Medium.woff2') format('woff2'),
        url('../assets/fonts/SVN-Poppins-Medium.woff') format('woff'),
        url('../assets/fonts/SVN-Poppins-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SVN-Poppins';
    src: url('../assets/fonts/SVN-Poppins-MediumItalic.woff2') format('woff2'),
        url('../assets/fonts/SVN-Poppins-MediumItalic.woff') format('woff'),
        url('../assets/fonts/SVN-Poppins-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SVN-Poppins';
    src: url('../assets/fonts/SVN-Poppins-LightItalic.woff2') format('woff2'),
        url('../assets/fonts/SVN-Poppins-LightItalic.woff') format('woff'),
        url('../assets/fonts/SVN-Poppins-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SVN-Poppins';
    src: url('../assets/fonts/SVN-Poppins-Regular.woff2') format('woff2'),
        url('../assets/fonts/SVN-Poppins-Regular.woff') format('woff'),
        url('../assets/fonts/SVN-Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SVN-Poppins';
    src: url('../assets/fonts/SVN-Poppins-SemiBold.woff2') format('woff2'),
        url('../assets/fonts/SVN-Poppins-SemiBold.woff') format('woff'),
        url('../assets/fonts/SVN-Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SVN-Poppins';
    src: url('../assets/fonts/SVN-Poppins-SemiBoldItalic.woff2') format('woff2'),
        url('../assets/fonts/SVN-Poppins-SemiBoldItalic.woff') format('woff'),
        url('../assets/fonts/SVN-Poppins-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SVN-Poppins';
    src: url('../assets/fonts/SVN-Poppins-Thin.woff2') format('woff2'),
        url('../assets/fonts/SVN-Poppins-Thin.woff') format('woff'),
        url('../assets/fonts/SVN-Poppins-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SVN-Poppins';
    src: url('../assets/fonts/SVN-Poppins-ThinItalic.woff2') format('woff2'),
        url('../assets/fonts/SVN-Poppins-ThinItalic.woff') format('woff'),
        url('../assets/fonts/SVN-Poppins-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

