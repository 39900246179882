@tailwind base;
@tailwind components;
@tailwind utilities;

/* stylelint-disable-next-line scss/at-rule-no-unknown */
@layer components {
    .button-base-style {
        @apply inline-flex items-center justify-center rounded-lg px-4 py-3 font-medium  transition duration-300 ease-in-out; 
    }

    .input-base-style {
        @apply border border-gainsboro rounded-lg text-base px-4 py-3 text-gunmetal h-12 focus:outline-none font-medium placeholder:text-roman-silver w-full transition duration-300 ease-in-out; 
    }
  
    .base-transition {
        @apply transition duration-300 ease-in-out;
    }

    .link-hovering {
        @apply hover:text-medium-turquoise2 base-transition; 
    }

    .container {
        @apply px-4 xl:px-8; 
    }

    .bg-page {
        @apply bg-alice-blue2 pb-8 lg:pb-12;
    }
}
@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.no-scrollbar::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
}

.no-scrollbar {
    -ms-overflow-style: none;  /* For Internet Explorer and Edge */
    scrollbar-width: none;  /* For Firefox */
}

.gradient-text {
    background: linear-gradient(to right, #7bcfff, #03f); /* Adjust colors as needed */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    font-size: 16px;
    @apply text-raisin-black font-poppins font-medium; 
}

.wrapper  &>div {
    padding-bottom: 48px;
}

@media only screen and (max-width: 768px) {
    .wrapper &>div {
        padding-bottom: 20px;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
